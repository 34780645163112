import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

import Layout from "../components/Layout";
import FeatureListBlocks from "../components/FeatureListBlocks";
import BlogRoll from "../components/BlogRoll";
import Features from "../components/Features";
import Header from "../components/Header";
import MobileScreens from "../components/MobileScreens";

import HeroFeatures from "../components/HeroFeatures";
import Footer from "../components/Footer";
import HeroHomeGradient from "../components/HeroHomeGradient";
import HeroHome from "../components/HeroHome";
import HorizontalHeroHome from "../components/HorizontalHeroHome";
import Stats from "../components/Stats";
import FeaturesZigzag from "../components/FeaturesZigzag";
import FeaturesBlocks from "../components/FeaturesBlocks";
import Demo from "../components/Demo";
import PricingTables from "../components/PricingTables";
import FeatureWalkthrough from "../components/FeatureWalkthrough";
import Cta from "../components/Cta";

import News from "../components/News";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FullWidthImage from "../components/FullWidthImage";
import DarkThemeDemo from "../components/DarkThemeDemo";
import { SlideshowLightbox } from "lightbox.js-react";

import 'lightbox.js-react/dist/index.css'


// eslint-disable-next-line
export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <div>
            <main className="grow">
              <Header />
              {/*  Page illustration */}
              {/* <div className="relative max-w-6xl mx-auto h-0 pointer-events-none" aria-hidden="true">
                <PageIllustration />
              </div> */}
                           {/* <img className="w-full h-40 rounded demoImg" src="https://images.pexels.com/photos/13425910/pexels-photo-13425910.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                              <img className="w-full h-40  rounded demoImg object-cover" src="https://images.pexels.com/photos/4275885/pexels-photo-4275885.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />  
                              <img className="w-full h-40  rounded demoImg object-cover" src="https://images.pexels.com/photos/13208323/pexels-photo-13208323.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                              <img className="w-full h-40  rounded demoImg object-cover" src="https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />       

                              <img className="w-full h-40 rounded demoImg object-cover" src="https://images.pexels.com/photos/11407630/pexels-photo-11407630.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                              <img className="w-full h-40 rounded demoImg object-cover" src="https://images.pexels.com/photos/13996896/pexels-photo-13996896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />   
                       */}


              {/*  Page sections */}
              <HeroHomeGradient />
              <FeatureListBlocks />
              <News />

              <FeatureWalkthrough />
              {/* <MobileScreens /> */}
              <Demo />

              {/* <HeroFeatures /> */}
              {/* <Stats /> */}
              {/* <FeaturesZigzag /> */}
              {/* <FeatureListBlocks /> */}
              <DarkThemeDemo />

              {/* <CaseStudies /> */}
              <PricingTables />
              <Cta /> 
              
              <a href="https://getisotope.com/?ref=lightboxjs">
                <button class="rounded-3xl px-4 py-3 fixed right-2 bottom-4
                bg-gradient-to-br from-blue-500 to-purple-500 text-gray-50">Generate HTML with AI</button>
              </a>


              </main>

              <Footer overrideDarkTheme={true}/>
      {/* <FullWidthImage img={heroImage} title={title} subheading={subheading} /> */}
      {/* <section className="section section--gradient">
        <div className="container">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="content">
                    <div className="tile">
                      <h1 className="title">{mainpitch.title}</h1>
                    </div>
                    <div className="tile">
                      <h3 className="subtitle">{mainpitch.description}</h3>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-2">
                        {heading}
                      </h3>
                      <h1 className="text-3xl font-bold underline">
                        Hello world!
                      </h1>
                      <p>{description}</p>
                    </div>
                  </div>
                  <Features gridItems={intro.blurbs} />
                  <div className="columns">
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/products">
                        See all products
                      </Link>
                    </div>
                  </div>
                  <div className="column is-12">
                    <h3 className="has-text-weight-semibold is-size-2">
                      Latest stories
                    </h3>
                    <BlogRoll />
                    <div className="column is-12 has-text-centered">
                      <Link className="btn" to="/blog">
                        Read more
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                gatsbyImageData(width: 240, quality: 64, layout: CONSTRAINED)
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
